@use "../../style/global/Colors";

.home_slider {
  display: flex;
  position: fixed;
  z-index: 9999;
  height: 70px;
  bottom: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #00000030;
  translate: transform(-200, 0);
  opacity: 0;
  animation: slide 10s ease-in-out;

  @keyframes slide {
    0% {
      translate: transform(-200, 0);
      opacity: 0;
    }
    10% {
      translate: transform(0, 0);
      opacity: 1;
    }
    80% {
      translate: transform(0, 0);
      opacity: 1;
    }
    100% {
      translate: transform(-200, 0);
      opacity: 0;
    }
  }

  p {
    text-align: center;
    color: white;
    font-size: 20px;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: Colors.$primaryDarkColor;
  z-index: 500;

  h2 {
    font-size: 16px;
    font-weight: 200;
    padding: 15px;
    text-align: center;
    color: Colors.$primaryLightColor;
  }
}
