.equipment {
  img {
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 600px;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  .equipment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      padding: 0;
      margin: 0;
      font-size: 24px;
      margin-top: 20px;
    }
    h2 {
      padding: 0;
      font-size: 30px;
      margin: 0;
      margin-top: 20px;
    }
    h1 {
      margin-top: 30px;
      font-size: 36px;
    }
  }
}
