@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;600;800&display=swap');

body {
  margin: 0;
  font-family: 'Dosis', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4{
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.leaflet-popup-content-wrapper {
  text-align: center!important;
}

.leaflet-tooltip-bottom:before {
  display: none!important;
}