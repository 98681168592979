@use "../../style/global/Colors";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: Colors.$primaryDarkColor;
  width: 100%;
  height: 60px;

  .nav_item_container {
    display: flex;
    background-color: Colors.$primaryDarkColor;
    cursor: pointer;
    height: 100%;
    width: 15vw;
    justify-content: center;
    align-items: center;
    transition: background-color 1s;

    h2 {
      font-size: 18px;
      font-weight: 400;
      color: Colors.$primaryLightColor;
      text-align: center;
    }
  }

  .nav_item_container:hover {
    background-color: Colors.$secondaryDarkColor;
  }
}

@media (max-width: 900px) {
  .header {
    .nav_item_container {
      width: 25%;
    }
  }
}

@media (max-width: 310px) {
  .header {
    display: none;
  }
}
