@use "../../style/global/Colors";

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.open_street_map {
  height: 100%;
  width: 100%;

  .custom_tooltip {
    background: none;
    border: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }

  .custom_icon {
    transition: all 0.5s;
  }

  .custom_icon:hover {
    filter: brightness(230%);
    rotate: -0.1deg;
  }

  .custom_popup {
    z-index: 1000;
    width: 300px;
  }

  .custom_button {
    border: 1px solid Colors.$primaryDarkColor;
    color: Colors.$primaryDarkColor;
    margin: 0 0 15px 0;
    font-weight: 400;
    text-transform: none;
  }

  .custom_button:hover {
    border: 1px solid Colors.$secondaryDarkColor;
    background: Colors.$primaryDarkColor;
    color: Colors.$primaryLightColor;
  }
}
