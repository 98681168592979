@use "../../style/global/Colors";

.home {
  width: 100%;
  height: 100vh;
  background: Colors.$primaryLightColor;

  h1 {
    padding: 1vw;
    text-align: center;
  }
}
