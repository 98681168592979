.gallery_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1vw 1vw;
  margin-bottom: 3vw;
  padding-bottom: 60px;

  .image_wrapper {
    width: 24%;
    height: 50vh;
    margin: 0.5vw 0.45vw;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s ease;
      cursor: pointer;
    }

    img:hover {
      transform: scale(1.1);
      filter: brightness(120%);
    }
  }

  .custom_lightbox {
    position: fixed;
    background: white;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 501;

    img.full_size_image {
      position: absolute;
      object-fit: contain;
      top: 50%;
      left: 50%;
      max-width: 90%;
      max-height: 90%;
      transform: translate(-50%, -50%);
    }

    img.close_icon {
      position: absolute;
      top: 1vw;
      right: 2vw;
      left: unset;
      z-index: 1;
      transform: unset;
      width: 30px;
      transition: 0.5s ease;
      cursor: pointer;
    }

    img.close_icon:hover {
      scale: 1.1;
    }

    img.left_arrow,
    img.right_arrow {
      position: absolute;
      top: 50%;
      left: 5vw;
      z-index: 1;
      opacity: 0.3;
      transform: translateY(-50%);
      transition: 0.5s ease;
      cursor: pointer;
    }

    img.right_arrow {
      right: 5vw;
      left: unset;
    }

    img.left_arrow:hover,
    img.right_arrow:hover {
      scale: 1.1;
    }
  }
}

@media (max-width: 950px) {
  .gallery_container {
    .image_wrapper {
      width: 49%;
    }
  }
}

@media (max-width: 768px) {
  .gallery_container {
    .image_wrapper {
      width: 100%;
    }
    .custom_lightbox {
      img.left_arrow {
        left: 1vw;
        z-index: 1;
        opacity: 0.3;
      }
      img.right_arrow {
        right: 1vw;
        opacity: 0.3;
      }
    }
  }
}
